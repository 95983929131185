import React from "react";
import { Button, Modal, Header } from "semantic-ui-react";

import "./ArchiveRequestModal.scss";

const ArchiveContent = ({ onAccept, onCancel, dismissModal }) => {
  return (
    <div className="archive-chat-modal-content">
      <div className="title">Archive Conversation</div>
      <div className="description">
        Are you sure you want to delete this conversation from the system?
      </div>

      <div className="buttons">
        <Button
          onClick={() => {
            dismissModal();
            if (onCancel) onCancel();
          }}
          className="button dismiss"
        >
          CANCEL
        </Button>
        <Button onClick={() => onAccept()} className="button danger">
          ARCHIVE CONVERSATION
        </Button>
      </div>
    </div>
  );
};

const ArchiveRequestModal = ({ id, onCancel, visible, setVisible }) => {
  const onAccept = () => {
    console.log("archived room id", id);
    setVisible(false);
  };

  return (
    <Modal
      className={`semanticModal archive-chat-request-modal`}
      style={{ backgroundColor: "#f8f8f8" }}
      size="small"
      onClose={() => setVisible(false)}
      onOpen={() => setVisible(true)}
      open={visible}
    >
      <Modal.Content style={{ backgroundColor: "#f8f8f8" }}>
        <ArchiveContent
          {...{ onAccept, onCancel, dismissModal: () => setVisible(false) }}
        />
      </Modal.Content>
    </Modal>
  );
};

export default ArchiveRequestModal;
